@import 'spectre-0.1.23/src/variables.less';

.ie-warning {
    display: block !important;
    background-color: @control-color-danger;
    font-weight: bold;
    color: #fff;
    p {
        margin: 0;
    }
}
